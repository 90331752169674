import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from '../../components/sidebar'
import img1 from "../../assets/img/telechargement/rehau/fiche-technique/Rehau__1.jpg"
import img2 from "../../assets/img/telechargement/rehau/fiche-technique/Rehau__2.jpg"
 
const RehauFichePage = () => {
    return(
        <Layout>
            <SEO title="REHAU fiche technique" />
            <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3"> 
            <div class="grid grid-cols-1 md:col-span-3 mx-3"> {/** first element */}
                <h1 class="text-2xl font-bold mb-2 md:mt-3">Fiche Technique</h1>
                <div class="border-solid border-2 border-red-500 w-1/12 mb-6"></div>
                <div class="grid grid-cols-1 gap-2">
                  <div class="relative">
                    <a href="https://www.rehau.com/download/909566/surfaces-rauvisio.pdf" target="top">
                      <img src={img1} alt="REHAU fiche technique" class="w-full" />
                      <p class="absolute top-1/3 font-bold text-black text-xl text-center mx-5">Information technique RAUVISIO mineral</p>
                      <p class="absolute ">Télécharger</p>
                      
                    </a>
                  </div>
                  <div class="relative">
                    <a href="https://www.rehau.com/download/897564/rauvisio-mineral.pdf" target="top">
                      <img src={img2} alt="REHAU fiche technique" class="w-full" />
                      <p class="absolute top-1/3 font-bold text-black text-xl text-center mx-5">RAUVISIO mineral</p>
                      <p class="absolute top-2/3 text-center block ">Télécharger</p>
                    
                    </a>
                  </div>
            </div>
            </div>
            <div>
                <Sidebar />
            </div>
            </div>
        </Layout>
) 
}
export default RehauFichePage